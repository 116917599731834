
.videoSlider > .swiper-button-next,  .videoSlider > .swiper-button-prev {
    display: none;
  }
 .customrReview > .swiper-button-next, .customrReview > .swiper-button-prev {
   color: var(--primaryColor);
   background: #fff;
   border-radius: 50%;
   width: 30px;
   height: 30px;

  }
  .customrReview > .swiper-button-prev:after, .customrReview >.swiper-button-next:after{
    font-size:20px !important;
  }
@media all and (max-width: 400px) {
  .videoSlider > .swiper-button-next,  .videoSlider > .swiper-button-prev {
    display: block;
    color: var(--primaryColor);
  }
}
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 .swiper-pagination-bullet{
    background:var(--primaryColor) ;
}
.erptitle{
  color: var(--primaryColor)
}

