.headerContainer {
  display: block;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px; */
  position: relative;
  z-index: 1000;
  background-color:var(--primaryColor)!important;
}

.linksWrapper .dropdown-toggle {
  display: flex;
  align-items: center;
}

.OffcanvasHeader.offcanvas-top.show {
  visibility: visible;
}

.OffcanvasHeader.offcanvas-top {
  height: min-content !important;
}

.OffcanvasHeader .offcanvas-body>div {
  text-align: center;
  padding: 15px;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px; */
}

.linksWrapper a {
  padding: 0 10px;
  color: #fff;
  background-color:var(--primaryColor)!important;
}

.linksWrapper a.active {
  color: #ffffffa2;
}

.dropdown-toggle::after {
  margin: 0 5px;
  font-size: 16px;
}

.dropdown-menu {
  /* min-width: 13rem !important; */
  text-align: unset !important;
  padding: 0 !important;
}

.dropDownLinks {
  display: flex;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  font-size: 13px !important;
}

.dropDownIcon {
  cursor: pointer;
  color: white !important;
}
.OffcanvasHeader.offcanvas-top.show {
 background: var(--primaryColor);
}
.offcanvas-body a {
  color: white !important;
}

.OffcanvasHeader .CollapseLink {
  text-decoration: none;
  font-weight: 500;
  outline: none !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: white !important;
}

.btn-check:focus+.btn,
.btn:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.linksWrapper .dropdown-item.active,
.linksWrapper .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e9ecef !important;
}

.langBtn{
  display: flex !important;
  align-items: center;
}
.linksWrapper a:hover {
  color: #ffffffa2;
}