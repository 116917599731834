.link-projects-card {
  display: flex;
  margin-bottom: 49px;
}

.link-projects-card:hover {
  color: white;
}

.link-projects-card:hover .CustomLinkText {
  color: white;
}
.projects-card {
  position: relative;
  padding: 20px 0;
  width: 100%;
  /* height: 100%; */
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.projects-card img {
  z-index: 99;
}

.projects-card .titleWrapper {
  position: absolute;
  bottom: -20px;
  height: 45px;
  background-color: white;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.8s ease;
}
.link-projects-card:hover .titleWrapper {
  background-color: var(--primaryColor);
}

/* .projects-card .titleWrapper .title{
  color: var(--);
} */
.projects-card::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fafafa;
  /* top: 10px;
  right: 5px; */
  /* opacity: .5; */
  position: absolute;
  padding: 20px 0;
  border-radius: 40px;
  display: flex;
  z-index: 0;
}

.socialContact:hover {
  transform: scale(1.1);
}
.socialContact {
  transition: transform 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 500px; */
  padding: 15px;
  /* height: 55px; */
  border-radius: 30px;
  background-color: rgb(228, 226, 223);
  margin-bottom: 15px;
  color: rgb(0, 0, 0);
}
