.brands .swiper {
    width: 100%;
    height: 100%;
}

.brands .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.ttt {
    width: 160px !important;
}

.brands .swiper-slide img {
    display: block;
    object-fit: cover;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

/* .brands .swiper-button-prev,
.brands .swiper-button-next {
    background-color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 15px;
} */

.brands .swiper-wrapper {
    transition-timing-function: linear;
}

/* .brands .swiper-button-prev {
    top: 25px;
    left: 50%;
    background-color: var(--primaryColor);
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    transform: translateX(-100%);
}
.brands .swiper-button-next {
    top: 25px;
    right: 55%;
    background-color: var(--primaryColor);
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    transform: translateX(-0%);
} */

.imgWrapper {
    width: 150px;
    height: 150px;
}

.slider {
    height: 200px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slider .slider-track {
    display: flex;
    width: calc(250px * 216);
    /* animation: scroll 200s linear infinite; */
    overflow: hidden;
}

.slider .slider-track.right {
    animation: scrollToRight 280s linear infinite;
}
.slider .slider-track.left {
    animation: scrollToLeft 280s linear infinite;
}

.slider .slider-track:hover {
    animation-play-state: paused;
}

@keyframes scrollToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 108));
    }
}

@keyframes scrollToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(250px * 108));
    }
}

.slider .slider-track .slide {
    height: 200px;
    width: 180px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.slider .slider-track .slide img {
    width: 100%;
}