.socialWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-bottom: 10px; */
}

.socialWrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(228, 226, 223);
    margin: 0 4px;
    color: rgb(0, 0, 0)
}