.homeSlider {
  position: relative; 
}

.homeSlider::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
   background-image: url("../../assets/img/panner1.svg");
}

.homeSlider.flip::before {
  content: "";
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.homeSlider .autoplay-progress-prev button {
  background: transparent;
}

.autoplay-progress svg {
  color:  white !important;

}

.homeSlider .swiper {
  width: 100%;
  position: initial;
 /* height: calc(80vh - 72px); */
 /* height: 600px; */
  
}


.homeSlider .swiper-slide {
  /* margin-top: -40px; */
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.homeSlider .autoplay-progress {
  position: absolute;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin:0px 30px ;
}

.homeSlider .autoplay-progress-next {
  right: 0;
  z-index: 1;
}

.homeSlider .autoplay-progress-prev {
  left: 0;
}

.homeSlider .autoplay-progress-next svg:first-child {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.homeSlider .autoplay-progress-prev svg:first-child {
  --progress: 1;
  position: absolute;
  left: -15px;
  top: -11px;
  z-index: 10;
  /* width: 100%;
  height: 100%; */
  width: 48px;
  height: 48px;
  stroke-width: 2px;
  stroke: white;;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.homeSlider .autoplay-progress-next svg:nth-child(2) {
  --progress: 1;
  position: absolute;
  left: 0;
  top: 0px;
  opacity: 0.2;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 2px;
  stroke:white;;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

@media all and (max-width: 900px) {
  .homeSlider .autoplay-progress {
    display: none;
  }
}

.slideWrapper {
  padding: 0 180px;
}

@media all and (max-width: 1500px) {
  .slideWrapper {
    padding: 0 120px;
  }
}

@media all and (max-width: 900px) {
  .slideWrapper {
    padding: 0 50px;
  }
  .hidden{
    display:none;
  }
  .padding{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media all and (max-width: 600px) {
  .slideWrapper {
    padding: 0;
  }
}