.link-service-card {
    display: flex;
    margin-bottom: 49px;
}

.link-service-card:hover {
    color: white;
}
.link-service-card:hover .CustomLinkText {
    color: white;
}
.service-card {
    position: relative;
    padding: 20px 0;
    width: 100%;
    /* height: 100%; */
    background-color: white;
    border-radius: 40px;
}

.service-card img {
    border-radius: 40px;
}

.service-card .titleWrapper {
    position: absolute;
    bottom: -20px;
    height: 45px;
    background-color: white;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.8s ease;
}
.link-service-card:hover .titleWrapper {
    background-color: var(--primaryColor);
}

/* .service-card .titleWrapper .title{
    color: var(--);
} */