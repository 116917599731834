.isArabic .form-floating > label {
  right: 0 !important;
  left: unset;
}

.isArabic .form-control.is-invalid,
.isArabic.was-validated .form-control:invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}
.isArabic .form-select.is-invalid,
.isArabic.was-validated .form-select:invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}
.isArabic .form-select {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}
