.blog {
  transition: all 0.5s ease;
}

.blog:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.blog-container .blog {
  border: 1px solid #eee;
  border-radius: 16px;
  margin-bottom: 15px;
  padding: 24px;
  text-align: center;
  min-height: 450px;
  line-height: 1.5;
  background-color: white;
  box-shadow: rgba(0, 0, 0, .25);
  box-sizing: border-box;
}

.blog-container .blog img {
  padding-bottom: 40px
}

.blog-container .blog .box-title {
  color: #052549;
  /* font-size: 24px; */
  margin-bottom: 30px
}

.blog-container .blog .box-info {
  color: #052549;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  width: 80%
}