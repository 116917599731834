.subTtitle {
  color: var(--secondColor);
}

.ParagraphText {
  display: flex;
  line-height: 2;
  font-size: 20px;
}
.CustomLinkText {
  /* text-decoration: none; */
  font-weight: 500;
  /* outline: none !important; */
  /* cursor: pointer; */
  font-size: 16px;
  line-height: 28px;
  /* transition: all 0.3s ease-in-out; */
  color: var(--secondColor);
}

@media all and (max-width: 600px) {
  .ParagraphText {
    font-size: 16px;
  }
}
@media all and (max-width: 1199px) {
  .disapper {
    display: none;
  }
}

@media all and (min-width: 1100px) {
  .logisticSubtitle {
    width: 55%;
    margin-top: 15px;
  }
}
/* .imgHeight{
    max-height: 500px;
} */