.elementor-button {
    font-weight: bold;
    line-height: 1.3em;
    fill: black;
    color: black;
    background-color: #FFFFFF00;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    padding: 8px;
}

.elementor-button:hover,
.elementor-button:focus {
    color: red;
    background-color: red;
    border-color: red;
}

.elementor-button:hover svg,
.elementor-button:focus svg {
    fill: red;
}