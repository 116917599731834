.paginate {
    display: flex;
    /* font-family: sans-serif; */
    margin: 20px auto;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
  }
  
  .pagination {
    list-style: none;
    /* height: 31.5px; */
    /* width: 31.5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
  }
  .pagination a{
    border-radius: 20px !important;
    margin: 10px;
  }
  .clientImg{
    max-width: 500px;
  }
  
  /* .active {
    background-color: #1e50ff;
    border-radius: 50%;
  }
  
  .page-item {
    list-style: none;
    padding: 2px 12px;
    height: 31.5px;
    background-color: #1e50ff;

    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  } */