.projectTab {
    background-color: transparent !important;
    position: relative;
    padding: 8px 8px;
}

.projectTab.active::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--primaryColor);
    bottom: 0;
    left: 0;
    margin-top: 2px;
    animation: border_anim 0.5s linear forwards;
}

.projectTab.active {
    color: var(--primaryColor);
}

.projectTab:hover {
    transition: all 0.3s ease-in-out;
    color: var(--primaryColor);
}


/* .border{
	position: relative;
	font-size: 18px;
}

.border:before{
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	border-bottom: solid 2px #000;
	animation: border_anim 3s linear forwards;
} */

@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}