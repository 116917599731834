/* .img {
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.ghgh {
  overflow: hidden;
  transition: all 0.5s ease;
  height: 360px !important;
}

.ghgh:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

}

.ghgh:hover .img {
  transform: scale(1.1);

}

.ghgh:hover .title {
  color: red !important;

} */
.servicesWrapper {
  padding: 0 80px;
  border-radius: 20px;
}

@media all and (max-width: 768px) {
  .servicesWrapper {
    padding: 0 120px;
  }
}

@media all and (max-width: 600px) {
  .servicesWrapper {
    padding: 0 70px;
  }
}

@media all and (max-width: 360px) {
  .servicesWrapper {
    padding: 0 20px;
  }
}

.servicesWrapper .line {
  width: 75%;
  height: 2px;
  margin-top: 8px;
  background-color: var(--primaryColor);
  opacity: 0.5;
  /* background-color: #a8a6a6; */
}

.servicesWrapper .line-two {
  width: 65%;
  height: 2px;
  margin-top: 4px;
  background-color: #d6d4d4;
}

.servicesWrapper .line-three {
  display: flex;
  width: 75%;
  height: 2px;
  margin-top: 4px;
  /* background-color: #a8a6a6; */
  opacity: 0.5;
  background-color: var(--primaryColor);
  margin-left: 25%;
}
.servicesWrapper .line-three.line-three-left {
  margin-left: 25%;
}
.servicesWrapper .line-three.line-three-right {
  margin-right: 25%;
}