.stageCard {
  position: relative;
  /* background-color: bisque; */
  /* margin: 0 2px; */
  height: 400px;
  box-sizing: border-box;
}

.stageCard .card-bgWrapper {
  border-bottom: 1px solid #cecaca;
  height: 30%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.stageCard .card-bgWrapper img {

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.stageCard .iconWrapper {
  position: absolute;
  width: 110px;
  height: 110px;
  left: 50%;
  background-color: #fafafa;
  transform: translateX(-50%);
  top: calc(30% - 55px);
  border-radius: 50%;
  /* background-repeat: round; */
  /* background-size: cover; */
  background-size: 55px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}

.stageCard .contentBg {
  min-height: 70%;
  /* color: white; */
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all .5s ease-in-out;
}

.stageCard .title {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.stageCard .desc {
  text-align: center;
  line-height: 1.8;
}

@media all and (max-width: 990px) {
  .stagesWrapper {
    padding: 0 220px;
  }
}

@media all and (max-width: 768px) {
  .stagesWrapper {
    padding: 0 120px;
  }
}

@media all and (max-width: 600px) {
  .stagesWrapper {
    padding: 0 70px;
  }
}

@media all and (max-width: 400px) {
  .stagesWrapper {
    padding: 0 0px;
  }
}

/* 
Credit to Eric Porter (http://codepen.io/EricPorter/pen/ObQrza) for the inspiration
This is my modification where the background stays instead of passes by 
*/

.contentBg>span.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  transition: all .3s ease-in-out;
}

/* button:hover {
  color: black;
} */

.stageCard:hover span.bg {
  transition: all .8s ease-in-out;
}


/* top */

.stageCard .bg {
  /* left: 0;
  bottom: 100%; */
  opacity: 0;
}

.stageCard:hover .bg {
  opacity: 1;
}